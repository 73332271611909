// @ts-ignore
import {Elm} from "./Main.elm";
import "@unocss/reset/tailwind.css";
import "../public/index.css";

const root = document.querySelector("#app");

const app = Elm.Main.init({node: root});

app.ports.openNewTab.subscribe((url: string | URL) => {
  window.open(url, null, "noreferrer");
});

document.addEventListener("mousemove", (e) => {
  const cursor: HTMLElement = document.querySelector(".custom-cursor");
  cursor.style.left = `${e.pageX}px`;
  cursor.style.top = `${e.pageY}px`;
});
