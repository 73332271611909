port module Main exposing (main)

import Array
import Browser exposing (Document, UrlRequest(..), application)
import Browser.Navigation as Nav
import Html exposing (Html, a, div, h3, main_, p, text)
import Html.Attributes exposing (class, href, id)
import Lib.Constants exposing (socialsArray)
import Lib.Types exposing (Model, Msg(..))
import Url exposing (Url)



-- VIEW


view : Model -> Document Msg
view _ =
    { title = "Anthony Stanislaus"
    , body =
        [ div
            [ id "main-container"
            , class "h-screen w-full px-[24%] py-[4%] bg-[#020202] text-[#D5F2FFFF] font-kode"
            ]
            [ main_
                [ id "resume"
                , class "h-full bg-[#E7F2F4FF] flex flex-col lg:grid lg:grid-cols-4 lg:grid-rows-8 lg:gap-px"
                ]
                (List.concat
                    [ emptyGridEdges
                    , mainContent :: List.reverse emptyGridEdges
                    ]
                )
            , div [ class "custom-cursor" ] []
            ]
        ]
    }


emptyGridEdges : List (Html Msg)
emptyGridEdges =
    let
        bgColor =
            "bg-[#020202]"

        outerCorner =
            bgColor ++ " lg:grid lg:col-span-1 lg:row-span-3"

        outerMiddle =
            bgColor ++ " lg:grid lg:col-span-3 lg:row-span-3"

        innerMiddle =
            bgColor ++ " lg:grid lg:col-span-1 lg:row-span-2"
    in
    [ div [ class outerMiddle ] []
    , div [ class outerCorner ] []
    , div [ class innerMiddle ] []
    ]


mainContent : Html Msg
mainContent =
    div
        [ class "bg-[#020202] grow place-content-center lg:col-span-2 lg:row-span-2 lg:px-3 lg:py-2 lg:flex lg:flex-col lg:gap-2" ]
        [ div [ class "invisible" ] [ h3 [ class "lg:text-sm" ] [ text "RESUME" ] ]
        , div
            [ class "grow text-center lg:text-right text-3xl font-barcode lg:indent-8" ]
            [ p [] [ text "anthony stanislaus" ]
            , p [] [ text "software engineer" ]
            ]
        , div
            [ id "social-url"
            , class "flex flex-col items-center gap-8 pt-2xl lg:flex-row lg:justify-between lg:pt-0"
            ]
            [ a
                [ href linkedInUrl
                , class "decoration-dotted hover:underline"
                ]
                [ text "LINKEDIN" ]
            , a
                [ href "https://read.cv/anthonystanislaus"
                , class "decoration-dotted hover:underline"
                ]
                [ text "CV" ]
            , p
                [ href ""
                , class "hidden decoration-dotted hover:underline lg:block"
                ]
                [ text "GITLAB" ]
            ]
        ]


linkedInUrl : String
linkedInUrl =
    socialsArray
        |> Array.get 0
        |> Maybe.withDefault ( "", text "" )
        |> Tuple.first



-- UPDATE


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        UrlRequested urlRequest ->
            case urlRequest of
                Internal path ->
                    ( model, Nav.pushUrl model.key <| Url.toString path )

                External href ->
                    ( model, openNewTab href )

        UrlChanged url ->
            ( { model | url = url }, Cmd.none )



-- MAIN


main : Program () Model Msg
main =
    application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = UrlRequested
        , onUrlChange = UrlChanged
        }


init : () -> Url -> Nav.Key -> ( Model, Cmd Msg )
init _ url key =
    ( Model url key, Cmd.none )


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.none


port openNewTab : String -> Cmd msg
